import React from "react";
import Layout from "../components/layout";
import HeroPage from "../components/HeroPage";
import { Link, graphql } from "gatsby";
import { Container, Markdown } from "../components/global";
import { getVideoTitle } from "../utils";
import { md } from "../constants/MediaQueries";
import styled from "styled-components";
import { navy, blue } from "../constants/Colors";

const Outer = styled.div`
    padding: 50px 0;
    ${md} {
        padding: 100px 0;
    }
`;
const Inner = styled.div`
    max-width: 800px;
    margin: 0 auto;
`;

const Sep = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${navy};
    margin: 36px 0;
`;

const Ul = styled.ul`
    li {
        line-height: 1.5;
        margin: 0;
    }
`;

const VideoArchive = ({ videos }: { videos?: any[] }) => {
    if (!videos || videos.length === 0) return null;
    return (
        <>
            <Sep></Sep>
            <h3>Previous videos</h3>
            <Ul>
                {videos.map(video => (
                    <li key={video.name}>
                        <Link to={`/videos/${video.name}`}>
                            {getVideoTitle(
                                video.childMarkdownRemark.frontmatter.title
                            )}
                        </Link>
                    </li>
                ))}
            </Ul>
        </>
    );
};

const VideosPage = props => {
    const [firstVideo, ...videos]: any[] = props.data.allFile.edges.map(
        ({ node }) => node
    );
    console.log({ firstVideo, videos });
    return (
        <Layout>
            <HeroPage title="Videos"></HeroPage>
            <Outer>
                <Container>
                    <Inner>
                        <h2 style={{ marginBottom: 10 }}>
                            {getVideoTitle(
                                firstVideo.childMarkdownRemark.frontmatter.title
                            )}
                        </h2>
                        <Markdown input={firstVideo.childMarkdownRemark.html} />
                        <VideoArchive videos={videos} />
                    </Inner>
                </Container>
            </Outer>
        </Layout>
    );
};

export const query = graphql`
    {
        allFile(
            filter: { sourceInstanceName: { eq: "videos" } }
            sort: {
                order: DESC
                fields: childMarkdownRemark___frontmatter___date
            }
        ) {
            edges {
                node {
                    name
                    modifiedTime
                    childMarkdownRemark {
                        frontmatter {
                            title
                            date
                        }
                        html
                    }
                }
            }
        }
    }
`;

export default VideosPage;
